export default {
    state: {
		isEventsLoaded: false,
		locations_list: [],
		location_settings: {},
		catalog: [],
        currentEvent: {},
        participants: {},
        calendar: [],
        selectedDates: [],
        timeslots: [],
        mapPoints:[],
        mapSettings:{},
        selectedSlot: null,
        pricingDetails:{},
        additionalServices:[]
    },
    mutations: {
		setLocations (state, payload) {
            state.locations_list = payload;
        },
		setEvents (state, payload) {
            state.isEventsLoaded = true;
			state.catalog = payload.catalog;
			state.location_settings = payload.location;
        },
        clearEvents (state) {
            state.isEventsLoaded = false;
            state.catalog = [];
            state.location_settings = {};
        },
        setCurrentEvent (state, payload) {
            state.currentEvent = payload;
        },
        setParticipants (state, payload) {
            state.participants = payload;
        },
        setMainCalendar (state, payload) {
            state.calendar = payload;
        },
        setCalendarItem (state, payload) {
            let copy = [];
            const cloneCalendar = JSON.parse(JSON.stringify(state.calendar));
            if (payload.type == 'prev'){
                copy.push(payload.calendar);
                copy.push(cloneCalendar[0]);
            }
            else{
                copy.push(cloneCalendar[1]);
                copy.push(payload.calendar);
            }
            state.calendar = copy;
        },
        setCalendarItemRefresh (state, payload) {
            let copy = [];
            const cloneCalendar = JSON.parse(JSON.stringify(state.calendar));
            if (payload.type == 'prev'){
                copy.push(payload.calendar);
                copy.push(cloneCalendar[1]);
            }
            else{
                copy.push(cloneCalendar[0]);
                copy.push(payload.calendar);
            }
            state.calendar = copy;
        },
        setSelectedDate (state, payload) {
            if (state.selectedDates[0] == payload){
                state.selectedDates.splice(0, 1);
                // delete state.selectedDates[0];
            }
            else{
                state.selectedDates[0] = payload;
            }
            // const indx = state.selectedDates.findIndex((i) => i == payload);
            // if ( indx != -1){
            //     delete state.selectedDates[indx];
            // }
            // else{
            //     state.selectedDates.push(payload);
            // }
        },
        setTimeslots (state, payload) {
            state.timeslots = payload;
        },
        resetDates (state) {
            state.timeslots = [];
            state.selectedDates = [];
            state.selectedSlot = null;
        },
        resetCalendar (state) {
            state.calendar = [];
            state.timeslots = [];
            state.selectedDates = [];
            state.mapPoints = [];
            state.mapSettings = {};
            state.selectedSlot = null;
        },
        setMapPoints (state, payload) {
            state.mapPoints = payload;
        },
        setMapSettings (state, payload) {
            state.mapSettings = payload;
        },
        setSelectedSlot (state, payload) {
            state.selectedSlot = payload;
        },
        setPricingDetails (state, payload) {
            state.pricingDetails = payload;
        },
        setAdditionalServices (state, payload) {
            state.additionalServices = payload;
        },
        updateCurrentEvent (state, payload) {
            state.currentEvent.price = payload.price;
            state.currentEvent.capacity = payload.capacity;
            state.currentEvent.additional_prices = payload.additional_prices;
        },
    },
    actions: {
		setLocations ({commit}, payload) {
            commit('setLocations', payload.locations_list);
        },
		setEvents ({commit}, payload) {
            commit('setEvents', payload);
        },
        clearEvents({commit}){
            commit('clearEvents');
        },
        setCurrentEvent ({commit}, payload) {
            commit('setCurrentEvent', payload);
        },
        setParticipants ({commit}, payload) {
            commit('setParticipants', payload);
        },
        setMainCalendar ({commit}, payload) {
            commit('setMainCalendar', payload);
        },
        setCalendarItem ({commit}, payload) {
            commit('setCalendarItem', payload);
        },
        setCalendarItemRefresh ({commit}, payload) {
            commit('setCalendarItemRefresh', payload);
        },
        setSelectedDate ({commit}, payload) {
            commit('setSelectedDate', payload);
        },
        setTimeslots ({commit}, payload) {
            commit('setTimeslots', payload);
        },
        resetDates ({commit}) {
            commit('resetDates');
        },
        resetCalendar ({commit}) {
            commit('resetCalendar');
        },
        setMapPoints ({commit}, payload) {
            commit('setMapPoints', payload);
        },
        setMapSettings ({commit}, payload) {
            commit('setMapSettings', payload);
        },
        setSelectedSlot ({commit}, payload) {
            commit('setSelectedSlot', payload);
        },
        setPricingDetails ({commit}, payload) {
            commit('setPricingDetails', payload);
        },
        setAdditionalServices ({commit}, payload) {
            commit('setAdditionalServices', payload);
        },
        updateCurrentEvent ({commit}, payload) {
            commit('updateCurrentEvent', payload);
        },
    },
    getters: {
		getLocations (state) {
            return state.locations_list;
        },
		getLocation (state) {
            return state.location_settings;
        },
		getEventsStatus (state) {
            return state.isEventsLoaded;
        },
		getCatalog (state) {
            return state.catalog;
        },
        getLocationStatus: (state) => (payload) => {
            if (!state.isEventsLoaded) return false;
            if (state.catalog[0].location_id != payload) return false;
            return true;
        },
        getCurrentEvent (state) {
            return state.currentEvent;
        },
        getParticipants (state) {
            return state.participants;
        },
        getCalendar (state) {
            return state.calendar;
        },
        getSelectedDates (state) {
            return state.selectedDates;
        },
        getTimeslots (state) {
            return state.timeslots;
        },
        getMapPoints (state) {
            return state.mapPoints;
        },
        getMapSettings (state) {
            return state.mapSettings;
        },
        getSelectedSlot (state) {
            return state.selectedSlot;
        },
        getPricingDetails (state) {
            return state.pricingDetails;
        },
        getAdditionalServices (state) {
            return state.additionalServices;
        },
    }
}
