import store from '../../store/index'

export default function (to, from, next) {
	store.dispatch('setLoading', true);
	store.dispatch('resetCalendar');
	store.dispatch('getMapPoints', to.params).then((ret) => {
		if (store.getters.getError == null){
			store.dispatch('setMapPoints', ret.data.collection.features);
			store.dispatch('setMapSettings', ret.data.settings);
			store.dispatch('setLoading', false);
			store.dispatch('setNoNeedCalendarUpdate', true);
			next();
		}
	});
}
