import store from '../../store/index'

export default function (to, from, next) {
	store.dispatch('setLoading', true);
	store.dispatch('resetCalendar');
	store.dispatch('getMainCalendar', to.params).then((ret) => {
		if (store.getters.getError == null){
			store.dispatch('setMainCalendar', ret.data.calendar);
			store.dispatch('setLoading', false);
			store.dispatch('setNoNeedCalendarUpdate', true);
			next();
		}
	});
}
